.block.squared-article {
    position: relative;

    width: 100%;
    height: 100%;
    min-height: 250px;

    display: flex;

    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    padding: 2rem;

    &.align-horizontal-left {
        align-items: flex-start;
    }
    &.align-horizontal-right {
        align-items: flex-end;
    }

    &.align-vertical-top {
        justify-content: flex-start;
    }
    &.align-vertical-bottom {
        justify-content: flex-end;
    }

    &.grey-background {
        background-color: var(--light-grey-color, grey);
    }

    &.text-with-image {
        @include texteditor-text;

        justify-content: center;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        z-index: -1;
    }

    > .img{
        margin-bottom: 1rem;

        img {
            max-width: 70px;
        }
    } 

}