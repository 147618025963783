.block.group-squares:not(.wrapper){

    .layoutSlots > .LayoutSlot {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: auto;
        gap: 1rem 1rem;

        @include media("<medium") {
            grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
        }
        @include media("<small") {
            grid-template-columns: 1fr;
        }
    }
}