.block:not(.wrapper) {
    margin-bottom: var(--blocks-gap-between-blocks, 0);

    @include media("<medium"){
        margin-bottom: var(--blocks-gap-between-blocks-mobile, 0);
    }

    &.no-margin-bottom {
        margin-bottom: 0;

        @include media("<medium"){
            margin-bottom: 0;
        }
    }

    &.with-lightgrey-color-background {
        background-color: var(--light-grey-color, grey);
        padding: 3rem;
    }

    .with-inner-wrapper {
        @include max-width-outercontainer;

        > .inner-wrapper {
            @include max-width-innerwrapper;

            width: 100%;

        }
    }
}

.wrapper.block {
    @include max-width-outercontainer;

    > .container {
        @include max-width-innerwrapper;
        width: 100%;

        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .block {
        margin-bottom: 0 !important;
    }

    &.with-lightgrey-color-wrapper-background {
        background-color: var(--light-grey-color, grey);
    }
    &.with-main-color-background {
        background-color: var(--main-brand-color, inherit);
    }
}