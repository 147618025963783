footer#footer {

    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @include media("<medium"){
        margin-top: 1rem;
    }

    > .footer-row {
        @include max-width-outercontainer;

        background: var(--footer-bg-color, inherit);
            color: var(--footer-font-color, inherit);
            line-height: 1.8;
            font-weight: 300;

        &.first {
            background: transparent;
            > .row {
                border-top: 1px solid #707070;
            }
        }

        &.second {
            a {
                color: var(--footer-link-color, inherit);
                text-decoration: none;
                font-family: 'Open Sans';
            }
        }

        > .row {
            @include max-width-innerwrapper;

            padding-left: var(--global--content-padding-left, 0);
            padding-right: var(--global--content-padding-right, 0);
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    .row {
        display: flex;

        @include media("<medium"){
            flex-direction: column;
        }

        .col{
            flex: 1;
            margin-right: 1rem;
            padding-top: 1rem;

            &.third {
                flex: 1;
            }

            &.logo {
                padding-top: 0;
            }

            &.link-to-top-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            &.social {
                > .e.Article {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

                    @include media("<small") {
                        grid-template-columns: 1fr 1fr 1fr;
                    }

                    .figure {
                        flex: 1;

                        display: flex;
                        align-items: flex-start;
                        justify-items: center;
                        
                        a {
                            flex: 1;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }
                    }
                }
                > .e.Html {
                    
                    display: block;
                    a {
                        margin-right: 0.4rem;
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }

            h1, h2, h3, h4 {
                color: black;
                font-weight: bold;
                margin-bottom: .5rem;
                font-size: 140%;
            }

            &.first {
                h1, h2, h3, h4 {
                    color: var(--footer-first-headline-color, inherit);
                }
            }
            &.third {
                padding-right: 3rem;
            }
        }
    }

    .logo {
        max-width: 300px;
        height: auto;

        @include media("<medium"){
            max-width: 100%;
        }
    }
    ul.social {
        li {
            margin-bottom: .5rem;
        }
        a {
            font-size: 80%;
            i {
                margin-right: .5rem;
                width: 2rem;
                height: 2rem;
                border-radius: 2rem;

                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: var(--footer-social-icon-background-color, #fff);
                color: var(--footer-social-icon-color, #003B53);
                font-size: 140%;
            }
        }
    }
}