$page-max-width: 1200px;

:root {
    --main-font-family: 'Heebo', sans-serif;
    --main-bg-color: #fff;
    --main-font-color: #000;
    --main-line-height: 1.4;
    --main-letter-spacing: .02rem;

    --main-wrapper-max-width: 1200px;

    --main-brand-color: #A6C628;
    --font-color-on-main-brand-color: #000000;
    --second-brand-color: var(--main-brand-color, #fff);
    --font-color-on-second-brand-color: var(--font-color-on-main-brand-color, #000);

    --highlight-color: #F5F5F5;
    --light-grey-color: #F5F5F5;

    --bold-text-color: inherit;

    --menu-btn-line-height: 2px;
    --menu-btn-width: 25px;

    --animated-buttons-border-width: 1px;

    --footer-bg-color: var(--main-brand-color);
    --footer-font-color: var(--font-color-on-main-brand-color);
    --footer-link-color: #fff;


    --content-padding-left-screen: 2rem;
    --content-padding-right-screen: 2rem;
    --content-padding-left-mobile: 1rem;
    --content-padding-right-mobile: 1rem;

    --blocks-gap-between-blocks-mobile: 1rem;
    --blocks-gap-between-blocks: 2rem;

    --button-padding-vertical: .5rem;
    --button-font-weight: 300;

    --footer-first-headline-color: #000;
    --footer-headlines-color: #003B53;

    // global variabl, will be changed by media-queries
    --global--content-padding-left: var(--content-padding-left-screen);
    --global--content-padding-right: var(--content-padding-right-screen);

    @include media("<medium"){
        --global--content-padding-left: var(--content-padding-left-mobile);
        --global--content-padding-right: var(--content-padding-right-mobile);
    }
}

