.block.InteractiveMap {
    overflow: hidden;
    height: 30vh;
    position: relative;
    z-index: 80;

    > .map-canvas {
        width: 100% !important;
        height: 100% !important;
    }
}