.block.image-with-blocked-text {
    position: relative;
    min-height: 50vh;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: clamp(1rem, 12vw, 2rem);


    .content {
        background-color: var(--main-bg-color, #fff);
        color: var(--main-font-color, #fff);
        padding: 3rem;
        margin-top: 5rem;
        width: 50%;

        @include media("<medium"){
            margin-top: 2rem;
            width: 100%;
        }
        
        @include texteditor-text;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;

        z-index: -1;
    }
}