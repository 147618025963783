@mixin texteditor-text($text-pos: flex-start) {
    display: flex;
    justify-content: $text-pos;
    align-items: flex-start;
    flex-direction: column;


    h1 {
        color: var(--texteditor-headline-color, inherit);
        font-size: var(--texteditor-text-headline-size, 2rem);
        font-weight: 700;

        line-height: 1.1;

        strong {
            font-weight: bold;
        }
    }
    h2 {
       /* color: var(--texteditor-second-headline-color, inherit);*/
        color: black;
        opacity: .5;
        margin-bottom: .5rem;
        font-weight: 700;

        strong {
            font-weight: bold;
        }
    }

    h1 + p, h2 + p {
        margin-top: 2rem;
    }

    p {
        display: block;
        width: 100%;
        margin-bottom: var(--texteditor-text-p-margin-bottom, .5rem);
        font-weight: 300;
        line-height: var(--texteditor-text-p-line-height, 1.5);

        &:last-child {
            margin-bottom: 0;
        }

        strong {
            font-weight: bold;
        }
        i {
            font-style: italic;
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        sup, sub {
            position: relative;
            vertical-align: baseline;
            top: -0.4em;
         }
         sub { top: 0.4em; }


         > .button {
             margin-top: 1rem;
         }
    
    }

    blockquote {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1rem;
        border-left: .2rem solid var(--highlight-color, #000);
    }

    ol, ul {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-left: 1rem;
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: disc;
    }

    table {
        width: 100%;
        tbody {
            td {
                padding: .3rem;
                text-align: center;
                border-bottom: 1px solid var(--main-font-color);
                border-right: 1px solid var(--main-font-color);

                &:last-child {
                    border-right: 0;
                }
            }

            tr:last-child td {
                border-bottom: 0;
            }
        }
    }
}