.block.hero {
    min-height: 50vh;
    width: 100%;

    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    flex-direction: column;

    padding-left: var(--global--content-padding-left, 0);
    padding-right: var(--global--content-padding-right, 0);


    color: var(--font-color-on-main-brand-color, #fff);

    .inner-wrapper {
        @include max-width-innerwrapper;
        width: 100%;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        z-index: -1;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;

        //     display: block;
        //     width: 100%;
        //     height: 100%;

        //     background: var(--main-brand-color, transparent);
        //     opacity: .3;
        // }
    }

    h1 {
        display: inline-block;

        font-size: 170%;
        font-weight: 700;
        width: auto;
        margin-bottom: 2rem;
        line-height: 1.1;
        letter-spacing: .1rem;

        @include media("<small"){
            font-size: clamp(1rem, 12vw, 2rem);
        }

        background: #fff;
        padding: .5rem 1rem;

        strong {
            font-weight: bold;
        }
    }

    h2 {
        width: 70%;
        font-size: clamp(.8rem, 12vw, 1rem);
        text-transform: uppercase;
        margin-bottom: 1rem;

        @include media("<small"){
            font-size: 1rem;
        }
    }
}