.block.row-3 {
    .row.content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row: auto;
        gap: 1rem 1rem;

        @include media("<medium"){
            grid-template-columns: 1fr 1fr;
        }

        @include media("<small"){
            grid-template-columns: 1fr;
        }

        .e.Article {
            height: 100%;
            padding: 3rem;
            background-color: #fff;

            --texteditor-second-headline-color: var(--main-font-color);

            @include texteditor-text;

            h1, h2, h3 {
                font-weight: bold;
                display: block;
                margin-bottom: 1rem;
                font-size: 130%;
                opacity: 1;
            }

            h2 + p,
            h1 + p {
                margin-top: 0;
            }
        }
    }
}