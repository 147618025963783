.block.row-4 {
    .row.cols4 {
        display: flex;
        justify-content: center;
        // grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
        flex-wrap: wrap;

        .col {
            flex: 1;
            display: flex;

            max-width: 33.33%;

            @include media("<medium"){
                width: 50%;
                flex: none;
                max-width: 50%;
            }

            @include media("<small"){
                width: 100%;
                max-width: 100%;
            }

            .block {
                flex: 1;
            }

            &:first-child {
                background-color: var(--main-brand-color, inherit);
                color: var(--font-color-on-main-brand-color, inherit);
            }
            &:nth-child(2){
                background-color: #D0D0D0;
            }
            &:nth-child(3){
                background-color: #000000;
                color: #fff;
            }
            &:last-child {
                background-color: #8288B1;
                color: #fff;
            }
        }
    }
}