.block.content-1_3-2_3-dynamic:not(.wrapper) {
    .row {
        &.content {
            display: flex;

            @include media("<medium"){
                flex-direction: column;
            }

            > .col {
                
                &:first-child {
                    width: 40%;

                    @include media("<medium"){
                        width: auto;
                        margin-bottom: 1rem;
                    }
                }

                &:last-child {
                    flex: 1;
                    padding-left: 2rem;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @include media("<medium"){
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .content .col .text-only {
        p {
            width: 100%;
        }
    }

    .content .col .text-only.with-main-color-background {
        padding: 3rem 2rem;

        @include media("<medium") {
            padding: 2rem 1rem;
        }


        --texteditor-text-p-margin-bottom: 2rem;
    }
}