.block.left-text-right-image {
        display: flex;

        @include media("<medium"){
            flex-direction: column;
        }

        .left, .right {
            flex: 1;
        }


    .left {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        padding-right: 2rem;

        h1 {
            color: var(--main-brand-color, inherit);
            font-weight: bold;
            font-size: 130%;
            margin-bottom: .5rem;
        }
        h2 {
            color: var(--highlight-color, inherit);
            opacity: .5;
            font-size: 130%;
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}