.block.title-with-background-image {
    position: relative;
    min-height: var(--title-with-background-min-height, 25vh);
    padding-left: var(--global--content-padding-left, 0);
    padding-right: var(--global--content-padding-right, 0);

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: -1;
        
        figure {
            display: block;
            width: 100%;
            height: 100%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h1 {
        position: absolute;
        left: var(--global--content-padding-left, 0);
        bottom: 0;

        display: inline-block;
        margin-bottom: 2rem;
        background-color: var(--main-brand-color, #fff);
        color: var(--font-color-on-main-brand-color, #000);
        padding: 1rem;
        font-weight: bold;
        font-size: 150%; 
    }
}