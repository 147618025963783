@mixin webfont($style-name, $file, $weight:"", $style:"") {
    $filepath: "../fonts/" + $file;
    @font-face {
        font-family: '#{$style-name}';
        @if $weight != "" {
                font-weight: #{$weight};
            }
        @if $style != "" {
                font-style: #{$style};
            }
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
             url($filepath + ".woff2") format('woff2'),
             url($filepath + ".woff") format('woff'),
             url($filepath + ".ttf")  format('truetype'),
             url($filepath + ".svg#" + $style-name)  format('svg');
    }
}