// import fonts
// @import "import/fonts";

// import custom variables
@import 'libs/fontawesome-pro-6/functions';
@import 'libs/fontawesome-pro-6/variables';
@import 'libs/fontawesome-pro-6/mixins';
@import 'libs/fontawesome-pro-6/core';
@import 'libs/fontawesome-pro-6/sizing';
@import 'libs/fontawesome-pro-6/fixed-width';
// @import 'libs/fontawesome-pro-6/list';
@import 'libs/fontawesome-pro-6/bordered-pulled';
@import 'libs/fontawesome-pro-6/animated';
@import 'libs/fontawesome-pro-6/rotated-flipped';
@import 'libs/fontawesome-pro-6/stacked';
@import 'libs/fontawesome-pro-6/icons';
@import 'libs/fontawesome-pro-6/screen-reader';
@import 'libs/fontawesome-pro-6/solid';
@import 'libs/fontawesome-pro-6/brands';
@import 'libs/fontawesome-pro-6/light';
@import 'libs/fontawesome-pro-6/thin';


@import 'helpers/webfont';
@import 'webfonts';

@import 'variables';

@import 'helpers/reset';
@import 'helpers/media-queries';

@import "import/myvariables";

@import 'helpers/max-width-container';
@import 'helpers/buttons';
@import 'helpers/texteditor-text';
@import 'helpers/page-headline';

@import 'parts/header';
@import 'parts/footer';
@import 'parts/side-fixed-icons';


// @import 'libs/fontawesome-pro-6/fontawesome.scss';
// @import 'libs/fontawesome-pro-6/solid.scss';
// @import 'libs/fontawesome-pro-6/regular.scss';
// @import 'libs/fontawesome-pro-6/thin.scss';
// @import 'libs/fontawesome-pro-6/light.scss';
// @import 'libs/fontawesome-pro-6/brands.scss';
// @import 'libs/fontawesome-pro-6/_core.scss';
// @import 'libs/fontawesome-pro-6/_icons.scss';



@import 'blocks/blocks';

@import "../js/leaflet/leaflet"; 


html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
    background-color: var(--main-bg-color);
    color: var(--main-font-color);
    font-family: var(--main-font-family);
    line-height: var(--main-line-height, 1);
    letter-spacing: var(--main-letter-spacing, inherit);

    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;

    padding-top: 8rem;

    display: flex;
    flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .phone-number-line {
    font-size: 130%;
    color: #A22727;
    font-weight: 400;
    display: inline-block;
    margin-left: 1rem;

    @include media("<small"){
      display: block;
      text-align: center;
      padding-top: 15px;
    }

    &::before {
      @extend %fa-icon;
      @extend .fa-light;
      content: fa-content($fa-var-phone);

      display: inline-block;
      margin-right: .5rem;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

// .phone-icon-row {
//   display: inline-block;
//   margin-right: 2rem;
//   font-size: 80%;
//   margin-bottom: 1rem;

//   &:before {
//     @extend %fa-icon;
//     @extend .fas;
//     content: fa-content($fa-var-phone-alt);

//     font-size: 200%;
//     @include media("<small") {
//       font-size: 150%;
//     }

//     margin-right: 1rem;
//   }

//   strong {
//     display: inline-block;
//     font-size: 180%;
//     margin-right: 1rem;
//     @include media("<small") {
//       font-size: 130%;
//     }
//   }
// }
// .email-icon-row {
//   display: inline-block;
//   margin-right: 1rem;
//   font-size: 80%;
//   margin-bottom: 1rem;

//   &:before {
//     @extend %fa-icon;
//     @extend .fas;
//     content: fa-content($fa-var-envelope);
//     font-size: 200%;

//     margin-right: 1rem;

//     @include media("<small") {
//       font-size: 150%;
//     }
//   }

//   strong {
//     display: inline-block;
//     font-size: 180%;
//     margin-right: 1rem;

//     @include media("<small") {
//       font-size: 130%;
//     }
//   }
// }

// .enumeration-with {
//   display: flex;
//   justify-content: space-between;
//   align-content: center;
//   align-items: center;

//   padding: 1rem;
//   background: var(--highlight-color, inherit);
//   color: var(--font-color-on-main-brand-color, #000);
//   border-radius: .5rem;

//   margin-bottom: .5rem;
  
//   &.check-icon {
//     &:after {
//       @extend %fa-icon;
//       @extend .fas;
//       content: fa-content($fa-var-check);

//       margin-left: .5rem;
//       color: var(--font-color-on-main-brand-color, #000);

//       justify-self: flex-end;
//     }
//   }
//   &.check-square-icon {
//     &:after {
//       @extend %fa-icon;
//       @extend .fas;
//       content: fa-content($fa-var-check-square);

//       margin-left: .5rem;
//       color: var(--font-color-on-main-brand-color, #000);

//       justify-self: flex-end;
//     }
//   }
// }

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.page-content-holder {
  flex: 1;
}

.apple-appstore-line,
.google-playstore-line {
  color: var(--main-font-color);
  a {
    color: var(--main-font-color) !important;
  }

  display: inline-block;

  &::before {
    @extend %fa-icon;
    @extend .fa-brands;
    content: fa-content($fa-var-apple);

    display: inline-block;
    margin-right: .5rem;
  }
}

.google-playstore-line {
  &::before {
    content: fa-content($fa-var-google-play);
  }
}

.google-playstore-button,
.apple-appstore-button {
  a {
    color: transparent;
    display: inline-block;
    width: 200px;
    height: 60px;

    background: url("../img/logo-app-store.png") no-repeat;
    background-size: contain;

    text-indent: -9999em;
    overflow: hidden;
    margin-right: 1rem;
    margin-top: 1rem;
  }
}

.apple-appstore-button {
  a {
    background-image: url("../img/logo-google-play.png");
  }
}

.employee {
  padding: 0 !important;

  .img {
    img {
      width: 100%;
      height: auto;
      max-width: none;
    }
  }
}

.notdienst {
  &:not(.wrapper) {
    background-color: var(--light-grey-color,grey);
    padding: 25px;

    p {
      strong {
        font-size: 18px;
        font-weight: bold;
        display: block;
        padding-bottom: 10px;
      }

      a {
        text-decoration: none;
        color: black;

        &:before {
          font-family: 'Font Awesome 6 Pro';
          font-style: normal;
          font-weight: 600;
          content:"\f08e";
          color: var(--main-brand-color);
          padding-right: 5px;
        }

        &:hover {
          color: var(--main-brand-color);
        }
      }
    }
  }
}