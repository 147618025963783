@mixin max-width-outercontainer {
    display: flex;
    justify-content: center;
}
@mixin max-width-innerwrapper {
    width: 100%;
    max-width: var(--main-wrapper-max-width);

    padding-left: var(--global--content-padding-left);
    padding-right: var(--global--content-padding-right);

    @include media("<large"){
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
@mixin max-width-container {
    display: flex;
    justify-content: center;

    > .wrapper {
        width: 100%;
        max-width: var(--main-wrapper-max-width);

        @include media("<large"){
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
}

.page-container {
    @include max-width-outercontainer;
    height: 100%;

    > .page-wrapper {
        @include max-width-innerwrapper;

        display: flex;
        flex-direction: column;
        > .page-content-holder {
            flex: 1;
        }
    }
}