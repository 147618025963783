.side-fixed-icons-holder {
    position: fixed;
    top: 50vh;
    right: 0;
    transform: translateY(-50%);

    ul {
        
        li {
            position: relative;
            margin-bottom: .5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            position: relative;
            right: -1rem;

            display: flex;
            padding: .5rem;
            background-color: var(--main-brand-color);
            color: var(--font-color-on-main-brand-color);
            font-size: 140%;

            justify-content: center;
            align-items: center;
            text-decoration: none;

            transition: all .1s ease-in-out;
            margin-left: 1rem;
            padding-right: 1.5rem;

            &:hover {
                right: 0rem;
            }
        }
    }
}