@mixin animated-outlined-button {
    position: relative;
        color: var(--main-font-color);
        text-transform: uppercase;
        font-weight: 200;

        letter-spacing: .2rem;

    &.menu {
        cursor: pointer;
        
        --btn-width: var(--menu-btn-width, 30px);
        --line-weight: var(--menu-btn-line-height, 4px);

        position: relative;
        display: block;

        width: var(--btn-width);
        height: var(--btn-width);
        overflow: hidden;
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        min-width: 0;

        
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 0;
            border-top: var(--line-weight) solid var(--main-font-color);
            transition: 0.5s;
        }

        &::after {
            top: auto;
            bottom: 0;
        }

        > span {
            display: block;
            height: 0;
            border-top: var(--line-weight) solid var(--main-font-color);
            transition: 0.5s;
        }

        body.menu-open & {
            &::before {
                transform: translateY(12px) rotate(135deg);
            }
            &::after {
                transform: translateY(-12px) rotate(-135deg);
            }
            > span {
                transform: scale(0);
            }
        }
    }
}

@mixin button($with-arrow: true, $with-shadow: false) {
    position: relative;

    display: inline-block;
    

    text-transform: uppercase;
    text-align: center;

    border: 1px solid transparent;

    background-color: var(--button-main-color, #fff);
    border-radius: var(--button-border-radius, 2rem);
    color: var(--button-font-color-on-main-color, #000);

    font-family: 'Open Sans';
    font-weight: 600;
    font-weight: var(--button-font-weight, 300);
    text-decoration: none;
    font-size: 80%;


    @if($with-shadow == true) {
        filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.16));
    }

    strong {
        font-weight: 600;
    }

    // fontawesome icons
    i {
        color: var(--main-brand-color);
    }

    a {
        display: block;
        padding: var(--button-padding-vertical, 1rem) var(--button-padding-horizontal, 2rem);

        @if($with-arrow == true) {
            padding-right: 1rem;
        }
        
        text-decoration: none !important;
        color: inherit;
        font-family: 'Open Sans';
        font-weight: 600;

        @if($with-arrow == true) {
            // arrow-right
            &::after {
                @extend %fa-icon;
                @extend .fa-solid;
                content: fa-content($fa-var-arrow-right);
                color: var(--main-brand-color, inherit);
                margin-left: .4rem;
            }
        }

        &:hover {
            @if($with-arrow == true) {
                padding-right: .7rem;

                &::after {
                    margin-left: .7rem;
                }
            }
        }
    }

    &:hover {
        background-color: var(--button-font-color-on-main-color, #fff);
        color: var(--button-main-color, #000);
    }

    &.button-blue-light {
        background-color: var(--second-brand-color, inherit);
        color: var(--font-color-on-second-brand-color, inherit);
    }

    &.with-shadow {
        filter: drop-shadow(0px 6px 25px rgba(0, 0, 0, 0.16));
    }
}

.btn {
    @include button;

    &.outlined-animated {
        @include animated-outlined-button;
    }
}

a.btn {
    padding: var(--button-padding-vertical, 1rem) var(--button-padding-horizontal, 2rem);
}

button {
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: inherit;
}

.button {
    @include button(true, true);
}