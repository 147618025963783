.page-wrapper {
    // @include media("<1000px") {
        padding-top: 90px;
    // }
}

header#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    background-color: var(--main-bg-color, inherit);
    padding-bottom: 1rem;

    .add-nav-border-bottom & {
        border-bottom: 1px solid #707070;
    }

    > .top-header-bar {

        @include max-width-outercontainer;
        
        > .inner-wrapper {
            @include max-width-innerwrapper;
            padding-top: .5rem;
            padding-bottom: .5rem;

            color: #888888;

            text-align: right;
            font-size: 80%;

            @include media("<1080px") {
                text-align: center;
            }
            
        }

        background-color: var(--light-grey-color, inherit);
    }

    > .navbar {
        @include max-width-outercontainer;
        padding-top: .5rem;
        
        > .inner-wrapper {
            @include max-width-innerwrapper;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    @include media("<1080px") {
        z-index: 90;

        background: var(--main-bg-color);

        left: 0;
        right: auto;
        margin-left: 0;
        margin-right: 0;
    }

    .navbar-brand {
        display: block;
        max-width: 300px;
        margin-right: 1rem;
    }

    .mobile-button-wrapper {
        display: none;
        position: relative;

        @include media("<1080px") {
            display: block;

            order: -10;
            margin-right: 1rem;

            position: relative;
            z-index: 310;

            body.menu-open & {

                .btn.outlined-animated.menu {
                    color: var(--font-color-on-main-brand-color);
                    span, &::before, &::after {
                        border-color: var(--font-color-on-main-brand-color);
                    }
                }
            }
        }
    }

    .navigation-wrapper {
        @include media("<1080px") {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 200;
            
            width: 300px;
            height: 100vh;

            background: var(--main-brand-color, inherit);
            color: var(--font-color-on-main-brand-color, #000);

            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            transform: translateX(-110%);

            transition: transform .2s ease-in-out 0s;

            padding-top: 3rem;
            padding-bottom: 2rem;
            animation-direction: forward;

            box-shadow: var(--mobile-menu-box-shadow, 13px 0px 31px 0px rgba(0,0,0,0.5));

            > ul.navbar-nav {
                width: 100%;
                flex: 1;

                flex-direction: column;

                a {
                    color: var(--font-color-on-main-brand-color, #000);
                }
                
                li {
                    margin-bottom: clamp(2rem, 12vh, 3rem);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                li#contact-page{
                    background: var(--font-color-on-main-brand-color, #000);
                    color: var(--main-brand-color, inherit);
                }
            }



            body.menu-open & {
                transform: translateX(0%);
                animation-direction: reverse;
            }
        }
    }

   ul.navbar-nav {
       display: flex;
       align-items: center;

       > li {
           margin-right: 1.25rem;
           white-space: nowrap;
           color: var(--header-nav-color, #000);
           text-decoration: none;
           font-family: 'Heebo';
           font-size: 100%;

           &:last-child {
               margin-right: 0;
           }

           &.active {
            //    a {
            //        font-weight: bold;
            //    }
            
           }

           > strong {
                color: grey;
           }

           &#nav-button{
               @include button(false);
               background-color: var(--main-brand-color);
               color: #fff;

               a {
                   color: #fff;
               }

               &:hover {
                   color: var(--main-brand-color);
                   border-color: var(--main-brand-color);

                   a {
                       color: var(--main-brand-color);
                   }
               }

               &.location > strong {
                    color: #fff;
                    font-weight: bold;
               }
               &.location:hover > strong {
                color: var(--main-brand-color);
           }

               > strong {
                    display: block;
                    padding: var(--button-padding-vertical, 1rem) var(--button-padding-horizontal, 2rem);
                    // color: var(--font-color-on-main-brand-color);
                    // font-weight: bold;
               }
           }

           &#online-shop {
            @include button(false);
            color: #000;
            background-color: var(--light-grey-color);

            a {
                color: #000;
            }

            &:hover {
                border-color: var(--light-grey-color);
            }
           }
       }

       a {
           display: block;
           color: var(--header-nav-color, #000);
           text-decoration: none;
           font-size: 100%;
           white-space: nowrap;

           &:hover {
                color: var(--header-nav-hover-color, #000);
                text-decoration: underline;
           }
       }
   } 
}