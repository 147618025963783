.block.contact-disturber {
    background-color: var(--main-brand-color, inherit);
    color: var(--font-color-on-main-brand-color, #fff);

    padding-left: var(--global--content-padding-left, 0);
    padding-right: var(--global--content-padding-right, 0);
    padding-top: 3rem;
    padding-bottom: 2rem;
    font-weight: 100;

    @include texteditor-text;

    h1 {
        color: inherit;
        font-weight: 300;
        font-size: 180%;

        width: 50%;
        margin-bottom: 1rem;

        strong {
            font-weight: bold;
        }

        @include media("<medium") {
            width: 100%;
        }

    }

    p {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}