.block.icon-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 30vh;

    text-align: center;

    font-family: 'Hind';
    padding: 1rem;
    overflow: hidden;


    aspect-ratio: 1 / 1;

    @include media("<small"){
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    figure.img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 

        // padding: clamp(1rem, 12vw, 2rem);
        padding: 0;
        height: 60px;

        @include media("<medium"){
            height: 60px;
        }

        @include media("<small"){
            height: 100px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    h1 {
        font-size: 1.1rem;
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: .5rem;
        text-transform: uppercase;
    }

    p {
        font-weight: 300;
        font-size: 80%;
        line-height: 1.5;
    }
    
}