.block.text-only {
    padding-left: var(--global--content-padding-left, 0);
    padding-right: var(--global--content-padding-right, 0);

    @include texteditor-text;

    p {
        width: 80%;

        @include media("<medium") {
            width: 100%;
        }
    }


    &.with-main-color-background {
        background-color: var(--main-brand-color, inherit);
        color: var(--font-color-on-main-brand-color, inherit)
    }

}