.block.square-image-right-text-left {
    display: flex;

    @include media("<medium") {
        flex-direction: column;
    }

    min-height: 30vh;

    body.grey-square-background &, &.with-grey-background {
        background: var(--light-grey-color, grey);
    }

    &.image-contain .right figure img {
        object-fit: contain;
    }

    &.not-squared {
        @include media(">large"){
            max-height: 50vh;
        }

        .left,
        .right {
            aspect-ratio: auto !important;
        }
    }

    &.with-main-color-as-background {
        background-color: var(--main-brand-color, inherit);
        color: var(--font-color-on-main-brand-color, inherit);
        
        .left > .inner-wrapper p > a {
                color: #fff;
        }
    }

    .left, 
    .right {
        flex: 1;

        aspect-ratio: 1 / 1;

        @include media("<medium"){
            aspect-ratio: auto;
        }
    }

    .right {
        display: flex;

        figure, .img {
            width: 100%;
            height: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        @include media("<medium"){
            order: -1;
        }
    }

    .left {
        position: relative;
        display: flex;
        justify-content: flex-end;
       

        

        flex: none;
        width: 50%;

        h1 {
            width: 80%;
        }

        @include media("<medium"){
            flex: 1;
            width: 100%;
        }

        > .inner-wrapper {
            width: 100%;
            max-width: calc($page-max-width/2);
            
            @include texteditor-text(center);

            padding: var(--content-padding-left-screen, 2rem);
            @include media("<medium") {
                padding: var(--content-padding-left-mobile, 1rem);
            }
        }

        span.certification-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;

            width: 180px;
            height: 180px;
            border-radius: 180px;
            padding: 1rem;
            background-color: var(--main-brand-color, #000);
            color: var(--font-color-on-main-brand-color, #fff);

            position: absolute;
            top: 2rem;
            right: 0;

            transform: translateX(70%);
            font-size: 70%;

            @include media("<medium"){
                left: 20%;
                position: absolute;
                top: 0;
                transform: translateX(-50%) translateY(-120%);
            }

            strong {
                display: block;
                font-weight: bold;
                margin-bottom: .2rem;
                font-size: 140%;
                line-height: 1;
            }
        }
    }

    // .button {
    //     a::after {
    //         @extend %fa-icon;
    //         @extend .fa-thin;
    //         content: fa-content($fa-var-arrow-right);

    //         margin-left: .5rem;
    //     }
    // }
}